import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./css/main.css"
import { Coloredbackground, NftCube, concert, creator1, creator2, creator3, creator4 } from "../img";
import { BiCube } from 'react-icons/bi';
import { BsArrowDownRight, BsBag } from 'react-icons/bs';
import { AiFillHeart } from 'react-icons/ai';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WelcomeTicket = () => {

  const currentDay = '2023-07-30T20:00';

  const [email, setEmail] = useState('');

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    getTimeDifference(currentDay);
    const interval = setInterval(() => getTimeDifference(currentDay), 1000);
    return () => clearInterval(interval);
  }, []);

  const leadingZero = (num) => {
    return num < 10 && num > 0 ? '0' + num : num;
  };

  const getTimeDifference = (currentDay) => {
    const time = Date.parse(currentDay) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };


  const craetors = [
    {
      id: 1,
      title: 'Lorem ipsume',
      img: NftCube,
      profileImg: creator4,
      name: 'ALICE HOPE',
      username: "@alicehope",
    },
    {
      id: 2,
      title: 'Lorem ipsume',
      img: NftCube,
      profileImg: creator1,
      name: 'ALICE HOPE',
      username: "@alicehope",
    }, {
      id: 3,
      title: 'Lorem ipsume',
      img: NftCube,
      profileImg: creator2,
      name: 'ALICE HOPE',
      username: "@alicehope",
    }, {
      id: 4,
      title: 'Lorem ipsume',
      img: NftCube,
      profileImg: creator3,
      name: 'ALICE HOPE',
      username: "@alicehope",
    }, {
      id: 5,
      title: 'Lorem ipsume',
      img: NftCube,
      profileImg: creator4,
      name: 'ALICE HOPE',
      username: "@alicehope",
    },
  ]

  const allFeatures = [
    {
      id: 1,
      icon: <BiCube />,
      title: 'Digital smart tickets',
      desc: 'You have complete control during your cycle of events, thanks to the real-time data showing the ownership and activity of the tickets.'
    },
    {
      id: 2,
      icon: <BsBag />,
      title: 'No more tickets scams',
      desc: 'Our tickets are registered on the blockchain and connected to the visitor’s phone, making any attempt at fraud impossible.'
    },
    {
      id: 3,
      icon: <BsBag />,
      title: 'Create, validate, control',
      desc: 'We not only allows the creation and validation of each type of ticket, but it also facilitates the resale within the platform in a controlled way.'
    },
    {
      id: 4,
      icon: <BiCube />,
      title: 'Serve all markets',
      desc: 'Create tickets for different events and places on the primary market and allow buyers to resell their tickets in the controlled secondary market.',
    },
    {
      id: 5,
      icon: <BsBag />,
      title: 'Your NFT Journy Starts Here.',
      desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati, doloribus?'
    },
    {
      id: 6,
      icon: <BsBag />,
      title: 'Your NFT Journy Starts Here.',
      desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati, doloribus?'
    },
  ];

  const handleStartBid = () => {
    alert('Bid Started');
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: true,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmail('');
    alert("Email sent");
  }

  return (
    <>
      <div className="w-100 ticket-welcome-main-container">
        <div className="ticket-welcome-header">
          <div className="d-flex justify-content-center align-items-center">
            <img src={Coloredbackground} alt="" className="rounded rounded-pill me-3" style={{ width: "15rem", height: "5rem" }} />
            <span className="text-dark fw-bold my-0" style={{ fontSize: "6.5rem" }}>Empowering</span>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <span className="text-dark fw-bold" style={{ lineHeight: "1.2rem", fontSize: "6.5rem" }}>The World</span>
            <div className="">
              <p className="text-dark m-0 p-0 ms-5">Lorem ipsum dolor sit amet.</p>
              <p className="text-dark m-0 p-0 ms-5">Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-start align-items-start">
              {
                craetors.map((data, index) => (
                  <img src={data.profileImg} key={index} alt="img"
                    className={`${index === 0 ? 'welcome-creator-profile1' : 'welcome-creator-profile'} mt-4`} />
                ))
              }
            </div>
            <span className="text-dark fw-bold my-0" style={{ fontSize: "6.5rem" }}>of Tickets</span>
            <button className="m-0 ms-4 px-5 py-3 join-button">Join as creator</button>
          </div>
        </div>
      </div>
      <div className="discover-section bg-dark pt-5 d-flex justify-content-center align-items-center">
        <div className="container" style={{ marginTop: "-10%" }}>
          <Slider {...sliderSettings}>
            {
              craetors.map((data, index) => (
                <div key={index}>
                  <div className="align-items-center py-2 px-2 mx-3 bg-white creator-container" style={{ borderRadius: "5px" }}>
                    <img src={data.img} alt="" className="px-1 creator-image" style={{}} />
                    <span className="d-block my-2">{data.name}</span>
                    <div className="d-flex justify-content-start align-items-start">
                      <img src={data.profileImg} style={{ width: "45px", height: "45px", borderRadius: "50%" }} />
                      <div className="mx-2">
                        <p className="m-0 p-0 text-dark">{data.name}</p>
                        <p className="m-0 p-0 ">{data.username}</p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center bg-white p-2 rounded rounded-circle border border-danger">
                        <AiFillHeart className="text-danger" />
                      </div>
                    </div>
                    <div className="p-2 my-2 text-center countdown">
                      <span className="m-0 me-1 p-0 countdown-value">
                        {leadingZero(days)}D:
                      </span>
                      <span className="m-0 me-1 p-0 countdown-value">
                        {leadingZero(hours)}H:
                      </span>
                      <span className="m-0 me-1 p-0 countdown-value">
                        {leadingZero(minutes)}M:
                      </span>
                      <span className="m-0 me-1 p-0 countdown-value">
                        {leadingZero(seconds)}S
                      </span>
                    </div>

                    <button className="start-bid-button rounded p-2 my-2 w-100" onClick={() => handleStartBid()}>Start Bid</button>
                  </div>
                </div>
              ))
            }
          </Slider>
          <div className="discover-header pt-5 text-center align-items-center my-5">
            <span className="text-center text-white discover-heading">Discover the future of event ticketing with our Platform </span>
          </div>
          <Row className="mx-5 px-5 my-5">
            {
              allFeatures.map((data, index) => (
                <Col sm={4} className="features py-3 my-5 px-5" key={index}>
                  <span className="fs-1 special-text">{data.icon}</span>
                  <span className="fs-3 d-block" style={{ fontWeight: "300" }}>{data.title}</span>
                  <p className="m-0 p-0 my-2 feature-description">{data.desc}</p>
                  <BsArrowDownRight className="fs-1" />
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
      <div className="newsletter-main d-flex m-0 p-0 py-5 justify-content-center align-items-center w-100 h-100" style={{ minHeight: "60vh" }}>
        <div className="container rounded mx-5 d-flex justify-content-between align-items-center">
          <div className="newsletter-main-container rounded p-5 w-75 h-100 container">
            <div>
              <span className="d-block text-white">Subscribe To Our</span>
              <h3 className="newsletter-heading d-inline" style={{ fontWeight: "300" }}>Newsletter</h3>
              <span className="newsletter-tagline text-white d-block">Stay in the Loop: Subscribe to Our Event Ticketing Newsletter and get updated with ongoing and upcoming events!</span>
              <form className="news-letter-form mt-4" onSubmit={handleSubmit}>
                <div className="newsletter-form-container p-2 rounded-pill d-flex justify-content-between align-items-center">
                  <input type="email" className="newsletter-form-input py-2 px-3 ms-3" value={email} placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
                  <button type="submit" className="newsletter-form-btn mx-3 px-5 py-2 text-white border-none"> Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeTicket;

