import React from "react";
import "./ticket.css"
import WelcomeTicket from "./WelcomeTicket";

import TicketEvents from "./TicketEvents";

const Ticket = ({theme}) => {
  return (
    <>
    <div className="ticket-min-height">
    <WelcomeTicket/>
    <TicketEvents />
    
    </div>
    </>
  )
};

export default Ticket;
