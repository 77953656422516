import React from 'react'
import { ticket } from '../img'

const TicketEvents = () => {
    return (
        <div className='ticket-events bg-white d-flex justify-content-center align-items-center'>
            <div className='container w-50 ticket-events-main d-flex justify-content-between align-items-center'>
                {/* <div className='ticket-event-side-container me-4'>
                    <img src={ticket} alt="" className='ticket-img'/>
                </div> */}
                <div className=''>
                    <h1 className='ticket-event-heading' style={{whiteSpace: 'nowrap'}}>Ticket your events with us</h1>
                    <p className='ticket-event-subheading'>SHARED ECONOMY COMMUNITY FOR EVENT PROMOTERS</p>
                    <p className='event-ticket-desc'>
                        We creates a new revenue redistribution model for event promoters and travelers around the world. By mixing our blockchain
                        ticketing protocol with one-click-buy travel marketplace, Yacooba generates more revenues for promoters and rewards for audiences.
                    </p>
                    <p className='event-ticket-desc'>
                        So, if you're looking for a ticketing partner that will maximise sales, while taking care of both you and your customers,
                        please fill out the form below and one of our team will get back in touch.
                    </p>
                    <p className='event-ticket-desc'>
                        We’re looking forward to hearing from you.
                    </p>
                    <div className='d-flex justify-content-end align-items-end'>
                        <button className='create-event-btn px-4 py-3'>Create your event</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketEvents
