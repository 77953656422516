import logo from './logo.svg';
import './App.css';
import Ticket from './Components/Ticket';

function App() {
  return (
    <div className="App">
      <Ticket/>
    </div>
  );
}

export default App;
